<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card-title class="justify-center">Configurações</v-card-title>
                <v-expansion-panels class="pa-5" focusable style="margin-bottom: 100px;">
                    <v-expansion-panel>
                        <v-expansion-panel-header>Geral</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        prepend-inner-icon="mdi-store"
                                        label="Nome Fantasia"
                                        v-model="form.fantasia"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        prepend-inner-icon="mdi-store"
                                        label="Razão Social"
                                        v-model="form.razao_social"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        prepend-inner-icon="mdi-whatsapp"
                                        label="WhatsApp"
                                        v-model="form.whatsapp"
                                        placeholder="(99) 99999-9999"
                                        v-mask="['(##) ####-####', '(##) #####-####']"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="usuario.tipo === 'suporte'">
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        prepend-inner-icon="mdi-domain"
                                        label="CNPJ"
                                        v-model="form.cnpj"
                                        placeholder="00.000.000/0000-00"
                                        v-mask="['##.###.###/####-##']"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        prepend-inner-icon="mdi-link-box-outline"
                                        label="Dominio"
                                        v-model="form.dominio"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                    <span class="caption">
                                        Somente letras, números e traços, não pode conter espaços e caracteres especiais. Ex válido: meu-restaurante-10
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" align="center" justify="center">
                                    <div class="color-gray">
                                        <v-icon>mdi-clock-outline</v-icon>
                                        Tempo de <b>entrega</b>
                                    </div>
                                    <v-range-slider
                                        v-model="tempoEntrega"
                                        min="0"
                                        max="180"
                                        step="5"
                                        hide-details
                                    />
                                    <h4 class="color-gray">
                                        {{ formatTimeString(tempoEntrega[0]) }} à {{ formatTimeString(tempoEntrega[1]) }}
                                    </h4>
                                </v-col>
                                <v-col cols="12" align="center" justify="center">
                                    <div class="color-gray">
                                        <v-icon>mdi-clock-outline</v-icon>
                                        Tempo de <b>retirada</b>
                                    </div>
                                    <v-range-slider
                                        v-model="tempoRetirada"
                                        min="0"
                                        max="180"
                                        step="5"
                                        hide-details
                                    />
                                    <h4 class="color-gray">
                                        {{ formatTimeString(tempoRetirada[0]) }} à {{ formatTimeString(tempoRetirada[1]) }}
                                    </h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        label="Slogan do rodapé"
                                        v-model="form.slogan"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        prepend-inner-icon="mdi-facebook"
                                        label="Link Facebook"
                                        v-model="form.facebook"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        prepend-inner-icon="mdi-instagram"
                                        label="Link do Instagram"
                                        v-model="form.instagram"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-switch
                                        dense
                                        inset
                                        class="pb-2"
                                        hide-details
                                        v-model="form.controle_estoque"
                                        label="Controlar estoque"
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-switch
                                        dense
                                        inset
                                        class="pb-2"
                                        hide-details
                                        v-model="form.exibir_estoque_cardapio"
                                        label="Exibir estoque no cardápio"
                                    />
                                </v-col>
                            </v-row>

                            <v-row class="mt-4">
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        label="Valor mínimo do pedido para entrega"
                                        v-model="form.pedido_minimo_entrega"
                                        v-mask="['#.##', '##.##', '###.##']"
                                        prefix="R$"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        label="Valor mínimo do pedido para retirada"
                                        v-model="form.pedido_minimo_retirada"
                                        v-mask="['#.##', '##.##', '###.##']"
                                        prefix="R$"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Taxa entrega grátis para pedidos à partir de"
                                        v-model="form.pedido_minimo_frete_gratis"
                                        v-mask="['#.##', '##.##', '###.##']"
                                        prefix="R$"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Valor limite da taxa grátis"
                                        v-model="form.valor_limite_taxa_gratis"
                                        v-mask="['#.##', '##.##', '###.##']"
                                        prefix="R$"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>

                            <v-switch
                                dense
                                inset
                                class="pb-2"
                                hide-details
                                v-model="form.confirmar_pedidos_automaticamente"
                                label="Aceitar pedidos automaticamente"
                            />
                            <v-slider
                                label="Volume do toque"
                                v-model="form.volumeSino"
                                prepend-icon="mdi-volume-high"
                                @end="playTest"
                            />
                            <v-select
                                @change="showCurrentTime"
                                v-model="form.timezone"
                                :items="timezones"
                                label="Fuso horário"
                                hide-details
                                outlined
                                dense
                            />
                            <v-switch
                                v-if="usuario.tipo === 'suporte'"
                                dense
                                inset
                                class="pb-2"
                                hide-details
                                v-model="form.permitir_anuncios"
                                label="Permitir anúncios do ZaperMenu no cardápio digital"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Endereço</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Municipio"
                                        v-model="form.municipio"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Rua/Avenida"
                                        v-model="form.rua"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        label="Bairro"
                                        v-model="form.bairro"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        label="Número"
                                        v-model="form.numero"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        label="UF"
                                        v-model="form.uf"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Formas de Entrega
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col>
                                    <v-switch
                                        v-model="delivery"
                                        dense
                                        inset
                                        hide-details
                                        class="pb-2"
                                        label="Delivery"
                                    />

                                    <v-switch
                                        v-model="retirada"
                                        dense
                                        inset
                                        class="pb-2"
                                        hide-details
                                        label="Retirada no Balcão"
                                    />

                                    <v-switch
                                        v-model="local"
                                        dense
                                        inset
                                        class="pb-4"
                                        hide-details
                                        label="Consumir no Local"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Taxa de Entrega</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col>
                                    <v-select
                                        v-model="form.frete_tipo"
                                        @change="onChangeFrete"
                                        :items="freteTipos"
                                        label="Taxa de entrega por"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="Geolocalização do estabelecimento"
                                        v-model="geolocalizacao"
                                        hide-details
                                        outlined
                                        dense
                                    >
                                        <template #append-outer>
                                            <v-btn icon color="primary">
                                                <v-icon @click="openGmaps" large>mdi-map-marker</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <MapViewModal
                                :visible="mapVisible"
                                @close="mapVisible = false"
                                @onChangePosition="onChangePosition($event)"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Impressão</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        :items="servicosImpressao"
                                        v-model="form.impressao.servicoImpressao"
                                        label="Serviço de impressão"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                    <div v-if="form.impressao.servicoImpressao === 'App ZaperMenu'">
                                        <v-btn @click="downloadPrintApp" small color="primary" text>
                                            Baixe o app de impressão para Android
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Tamanho da fonte na impressão"
                                        v-model="form.impressao.tamanhoFonte"
                                        type="number"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="form.impressao.servicoImpressao === 'App ZaperMenu'" style="margin-top: -20px;">
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="form.impressao.deviceTokenId"
                                        :items="deviceTokens"
                                        clearable
                                        :item-text="e => (e.user?.name || '')"
                                        item-value="id"
                                        label="Token do Dispositivo"
                                        outlined
                                        dense
                                        hide-details
                                    >
                                        <template #item="{ item }">
                                            <div class="py-1">
                                                <div>{{ item.token }}</div>
                                                <div class="caption">{{ item.user.id }} - {{ item.user.name }}</div>
                                                <div class="caption">{{ formatDate(item.created_at) }}</div>
                                            </div>
                                        </template>
                                        <template #selection="{ item }">
                                            <div>{{ item.token }}</div>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <fieldset class="v-fieldset mt-2 pl-4">
                                <legend class="v-legend">Quantidade de vias</legend>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="form.impressao.vias.delivery"
                                            :items="[1, 2, 3]"
                                            label="Delivery"
                                            hide-details
                                            outlined
                                            dense
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="form.impressao.vias.retirada"
                                            :items="[1, 2, 3]"
                                            label="Retirada no Balcão"
                                            hide-details
                                            outlined
                                            dense
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="form.impressao.vias.local"
                                            :items="[1, 2, 3]"
                                            label="Mesa Local"
                                            hide-details
                                            outlined
                                            dense
                                        />
                                    </v-col>
                                </v-row>
                            </fieldset>
                            <v-row class="mt-3">
                                <v-col cols="12">
                                    <v-select
                                        v-model="form.usuario_impressao"
                                        :items="users"
                                        :item-text="e => `${e.id} - ${e.name}`"
                                        item-value="id"
                                        clearable
                                        hint="Todas as impressões serão direcionada para o usuário selecionado"
                                        persistent-hint
                                        label="Terminal de Impressão"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Mensagem final da impressão"
                                        v-model="form.impressao.mensagemFinal"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>

                            <v-switch
                                dense
                                inset
                                hide-details
                                class="pb-2"
                                v-model="form.impressao.opcoesTipoAtivo"
                                label="Exibir opções de impressão (Completo e Resumido)"
                            />

                            <v-switch
                                dense
                                inset
                                hide-details
                                class="pb-2"
                                v-model="form.impressao.impressaoAutomatica"
                                label="Impressão automática de pedidos"
                            />

                            <v-switch
                                dense
                                inset
                                class="pb-2"
                                hide-details
                                v-model="form.impressao.linkImpressaoWhatsapp"
                                label="Link de impressão na mensagem do whatsApp"
                            />

                            <v-switch
                                dense
                                inset
                                class="pb-2"
                                hide-details
                                v-model="form.impressao.imprimirAlteracoes"
                                label="Imprimir pedido ao salvar alterações"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Horários de Funcionamento</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row align="center" v-for="d in form.horarios" :key="d.diaSemana">
                                <v-switch
                                    dense
                                    inset
                                    hide-details
                                    class="mr-2"
                                    style="width: 95px;"
                                    v-model="d.aberto"
                                    :label="d.diaSemana.substr(0, 3)"
                                />
                                <v-text-field
                                    v-model="d.horario"
                                    hide-details
                                    style="max-width: 105px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                    v-mask="['##:## - ##:##']"
                                    placeholder="00:00 - 00:00"
                                    :disabled="!d.aberto"
                                />
                                <v-btn
                                    icon
                                    small
                                    color="primary"
                                    v-if="!d.segundoHorarioAtivo"
                                    @click="toogleSegundoHorario(d.diaSemana, 'funcionamento')"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-text-field
                                    v-else
                                    v-model="d.segundoHorario"
                                    hide-details
                                    style="max-width: 130px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                    v-mask="['##:## - ##:##']"
                                    placeholder="00:00 - 00:00"
                                    :disabled="!d.aberto"
                                >
                                    <template v-slot:append-outer>
                                        <v-btn icon x-small @click="toogleSegundoHorario(d.diaSemana, 'funcionamento')">
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Agendamento de Pedidos</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-switch
                                dense
                                inset
                                class="pb-2"
                                hide-details
                                v-model="form.agendamento.ativo"
                                label="Permitir agendamento"
                            />
                            <v-switch
                                v-if="form.agendamento.ativo"
                                dense
                                inset
                                class="pb-2"
                                hide-details
                                v-model="form.agendamento.ocultarHorario"
                                label="Ocultar horário do agendamento"
                            />
                            <v-switch
                                v-if="form.agendamento.ativo"
                                dense
                                inset
                                class="pb-2"
                                hide-details
                                v-model="form.agendamento.obrigatorio"
                                label="Agendamento obrigatório"
                            />
                            <v-text-field
                                v-if="!form.agendamento.ocultarHorario"
                                v-model="form.agendamento.intervaloHorarioMinutos"
                                label="Intervalo do agendamento"
                                suffix="minutos"
                                placeholder="30"
                                v-mask="['##']"
                                class="py-3"
                                style="width: 50%;"
                                outlined
                                hide-details
                                dense
                            />
                            <fieldset v-if="form.agendamento.ativo" class="v-fieldset mt-2 pl-4">
                                <legend class="v-legend">Dias e horários disponíveis para agendamento</legend>
                                <v-row align="center" v-for="d in form.agendamento.horarios" :key="d.diaSemana">
                                    <v-switch
                                        dense
                                        inset
                                        hide-details
                                        class="mr-2"
                                        style="width: 95px;"
                                        v-model="d.aberto"
                                        :label="d.diaSemana.substr(0, 3)"
                                    />
                                    <v-text-field
                                        v-if="!form.agendamento.ocultarHorario"
                                        v-model="d.horario"
                                        hide-details
                                        style="max-width: 105px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                        v-mask="['##:## - ##:##']"
                                        placeholder="00:00 - 00:00"
                                        :disabled="!d.aberto"
                                    />
                                    <v-btn
                                        icon
                                        small
                                        color="primary"
                                        v-if="!d.segundoHorarioAtivo && !form.agendamento.ocultarHorario"
                                        @click="toogleSegundoHorario(d.diaSemana, 'agendamento')"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <v-text-field
                                        v-if="d.segundoHorarioAtivo && !form.agendamento.ocultarHorario"
                                        v-model="d.segundoHorario"
                                        hide-details
                                        style="max-width: 130px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                        v-mask="['##:## - ##:##']"
                                        placeholder="00:00 - 00:00"
                                        :disabled="!d.aberto"
                                    >
                                        <template v-slot:append-outer>
                                            <v-btn icon x-small @click="toogleSegundoHorario(d.diaSemana, 'agendamento')">
                                                <v-icon color="red">mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-row>
                            </fieldset>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Marketing</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Facebook Pixel ID"
                                        v-model="form.facebook_pixel_id"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Google Tag ID"
                                        v-model="form.google_tag_id"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <fieldset class="v-fieldset mt-2">
                                <legend class="v-legend">Banner promocional</legend>
                                <ImageInput
                                    v-model="form.banner"
                                    :autoResize="false"
                                />
                            </fieldset>
                            <fieldset class="v-fieldset">
                                <legend class="v-legend">
                                    <v-switch
                                        v-model="form.mural.ativo"
                                        label="Mural"
                                        class="pa-1 mb-3"
                                        hide-details
                                        dense
                                        inset
                                    />
                                </legend>

                                <v-textarea
                                    v-model="form.mural.mensagem"
                                    clear-icon="mdi-backspace-outline"
                                    label="Mensagem"
                                    outlined
                                    dense
                                    hide-details
                                    clearable
                                />
                                <div class="v-legend mt-2">Cor de fundo</div>
                                <v-color-picker
                                    v-model="form.mural.cor"
                                    dot-size="12"
                                    hide-inputs
                                    hide-mode-switch
                                    mode="hexa"
                                    class="mt-2"
                                />
                            </fieldset>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Logomarca, Tema e Cores</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <ImageInput
                                v-model="form.logo"
                                :autoResize="false"
                            />
                            <v-switch
                                dense
                                inset
                                v-model="form.tema.isDark"
                                :label="form.tema.isDark ? 'Tema escuro' : 'Tema claro'"
                            />
                            <fieldset class="v-fieldset mt-2">
                                <legend class="v-legend">Cor principal</legend>
                                <v-color-picker
                                    v-model="form.tema.primaryColor"
                                    dot-size="12"
                                    hide-inputs
                                    hide-mode-switch
                                    mode="hexa"
                                />
                            </fieldset>
                            <fieldset class="mt-2 v-fieldset">
                                <legend class="v-legend">Cor da barra superior</legend>
                                <v-color-picker
                                    v-model="form.tema.appBarColor"
                                    dot-size="12"
                                    hide-inputs
                                    hide-mode-switch
                                    mode="hexa"
                                />
                            </fieldset>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Assinatura</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="WhatsApp do assinante"
                                        v-model="form.assinatura_whatsapp"
                                        placeholder="(99) 99999-9999"
                                        v-mask="['(##) ####-####', '(##) #####-####']"
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Conexão WhatsApp (Robô de Atendimento)</v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-2">
                            <div v-if="wppConected" class="mb-2">
                                <v-icon color="#4CAF50" style="font-size: 60px;">mdi-checkbox-marked-circle-outline</v-icon>
                                <span style="color: #4CAF50 ; font-size: 20px;" class="ml-2">Conectado</span>
                                <div class="ml-2 mt-2" style="color: #4CAF50;">
                                    {{ userWhatsapp }} - {{ userName }}
                                </div>
                            </div>

                            <v-btn
                                v-if="!wppConected"
                                color="success"
                                @click="connectWpp"
                            >
                                Conectar
                            </v-btn>

                            <v-btn
                                v-else-if="usuario.tipo === 'suporte'"
                                text
                                outlined
                                color="error"
                                @click="deleteWppInstance"
                            >
                                Desconectar
                            </v-btn>
                            <v-btn
                                v-if="wppInstanceKey && !wppConected"
                                color="error"
                                icon
                                @click="deleteWppInstance"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn v-if="wppInstanceKey && !wppConected" icon @click="qrbase64WppInstance">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                            <div v-if="wppInstanceKey && wppQrBase64 && !wppConected" :style="`${largeScreen ? 'display: flex;' : ''} color: #444;`">
                                <div>
                                    <span><b>Para disparar o status do pedido ao contato do WhatsApp:</b></span>
                                    <ol class="mt-4">
                                        <li>Abra o WhatsApp no seu celular.</li>
                                        <li>Toque em <b>Mais opções</b> ou <b>Configurações</b> e selecione Aparelhos conectados.</li>
                                        <li>Aponte seu celular para esta tela para capturar o código.</li>
                                    </ol>
                                </div>
                                <img width="220" height="220" :src="wppQrBase64"/>
                            </div>

                            <fieldset class="v-fieldset mt-2">
                                <legend class="v-legend">
                                    <v-switch
                                        v-model="form.whatsapp_bot_active"
                                        label="Disparo de mensagens"
                                        class="pa-1 mb-3"
                                        hide-details
                                        dense
                                        inset
                                    />
                                </legend>

                                <v-textarea
                                    v-model="form.whatsapp_greeting_message"
                                    label="Mensagem de Saudação"
                                    rows="6"
                                    class="mt-2 mb-6"
                                    clear-icon="mdi-backspace-outline"
                                    hide-details
                                    dense
                                    outlined
                                />

                                <v-textarea
                                    v-model="form.whatsapp_away_message"
                                    label="Mensagem de Ausência"
                                    rows="6"
                                    class="mt-2 mb-6"
                                    clear-icon="mdi-backspace-outline"
                                    hide-details
                                    dense
                                    outlined
                                />

                                <div v-for="(item, index) in form.whatsapp_order_status_messages" :key="index">
                                    <v-textarea
                                        :ref="item.status"
                                        v-model="item.message"
                                        :label="getLabel(item.status)"
                                        rows="2"
                                        class="my-2"
                                        clear-icon="mdi-backspace-outline"
                                        hide-details
                                        dense
                                        outlined
                                    >
                                        <template #append>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-chat-plus</v-icon>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-card-title>Adicionar Variável</v-card-title>

                                                    <v-list-item @click="addVariavel(item.status, 'nome')">
                                                        <v-list-item-title>
                                                            <v-icon class="mr-2">mdi-account</v-icon>
                                                            <span>Nome do Cliente</span>
                                                        </v-list-item-title>
                                                    </v-list-item>

                                                    <v-list-item @click="addVariavel(item.status, 'pedido')">
                                                        <v-list-item-title>
                                                            <v-icon class="mr-2">mdi-clipboard-list-outline</v-icon>
                                                            <span>Nº do Pedido</span>
                                                        </v-list-item-title>
                                                    </v-list-item>

                                                    <v-list-item @click="addVariavel(item.status, 'link')">
                                                        <v-list-item-title>
                                                            <v-icon class="mr-2">mdi-link-variant</v-icon>
                                                            <span>Link do Cardápio</span>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-textarea>
                                </div>

                                <v-card class="elevation-0">
                                    <v-card-text>
                                        <ul>
                                            <li>
                                                Coloque um asterisco (*) antes e depois da palavra ou frase para deixá-la em *<b>negrito</b>*.
                                            </li>
                                            <li>
                                                Coloque um underscore (_) antes e depois da palavra ou frase para deixá-la em _<i>itálico</i>_.
                                            </li>
                                            <li>
                                                Coloque um til (~) antes e depois da palavra ou frase para ~<s>riscá-la</s>~.
                                            </li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </fieldset>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="showOnlinePayment">
                        <v-expansion-panel-header>Pagamento Online</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-select
                                @change="onGatewaySelect"
                                :items="gateways"
                                v-model="gateway"
                                class="my-4"
                                label="Adicionar gateway"
                                item-text="descricao"
                                item-value="id"
                                hide-details
                                outlined
                                dense
                            />

                            <div v-for="(gateway, index) in form.gateways_pagamento" :key="index">
                                <fieldset class="mt-2 v-fieldset">
                                    <legend class="pb-3 v-legend">
                                        <v-switch
                                            dense
                                            inset
                                            hide-details
                                            v-model="gateway.ativo"
                                            :label="getGatewayName(gateway.type)"
                                        />
                                    </legend>

                                    <v-row v-if="gateway.type === 'mercado_pago'">
                                        <v-col cols="12" sm="5">
                                            <v-text-field
                                                label="Public Key"
                                                v-model="gateway.public_key"
                                                hide-details
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="5">
                                            <v-text-field
                                                label="Access Token"
                                                v-model="gateway.access_token"
                                                hide-details
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                    </v-row>
                                </fieldset>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Segurança</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field
                                label="Senha de Supervisão"
                                v-model="form.supervisor_password"
                                type="password"
                                outlined
                                dense
                                hide-details
                            />
                            <span class="mt-1 caption">
                                A senha de supervisão é solicitada toda vez que um usúario
                                sem permissões administrativas tentar alterar informações de um pedido.
                            </span>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>Venda Complementar (Order Bump)</span>
                            <v-spacer/>
                            <div>
                                <v-chip class="white--text" color="purple" small>
                                    Novidade
                                </v-chip>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        :items="getProdutos"
                                        v-model="orderBump"
                                        label="Produtos"
                                        item-text="descricao"
                                        item-value="produto_id"
                                        multiple
                                        chips
                                        small-chips
                                        deletable-chips
                                        clearable
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="6" sm="3">
                                    <v-text-field
                                        v-model="orderBumpMin"
                                        label="Min"
                                        type="number"
                                        dense
                                        outlined
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6" sm="3">
                                    <v-text-field
                                        v-model="orderBumpMax"
                                        label="Max"
                                        type="number"
                                        dense
                                        outlined
                                        hide-details
                                    />
                                </v-col>
                            </v-row>
                            <v-text-field
                                label="Título"
                                v-model="orderBumpTitulo"
                                class="mt-4"
                                outlined
                                dense
                                hide-details
                            />
                            <v-select
                                :items="grupos"
                                v-model="orderBumpGroupsInclude"
                                class="my-4"
                                label="Quando o carrinho tiver"
                                item-text="descricao"
                                item-value="id"
                                clearable
                                multiple
                                chips
                                deletable-chips
                                small-chips
                                hide-details
                                outlined
                                dense
                            />
                            <v-select
                                :items="grupos"
                                v-model="orderBumpGroupsNotInclude"
                                class="my-4"
                                label="Quando o carrinho não tiver"
                                item-text="descricao"
                                item-value="id"
                                clearable
                                multiple
                                chips
                                deletable-chips
                                small-chips
                                hide-details
                                outlined
                                dense
                            />
                            <div>
                                <span class="caption">A etapa de venda complementar é exibida na finalização do pedido sempre que o cliente comprar um item dos grupos selecionados.</span>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="hasFidelidade">
                        <v-expansion-panel-header>
                            <span>Programa de Fidelidade</span>
                            <v-spacer/>
                            <div>
                                <v-chip class="white--text" color="purple" small>
                                    Novidade
                                </v-chip>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-switch
                                        v-model="form.fidelidade.pontuador_ativo"
                                        label="Ativar pontuador"
                                        class="pb-2"
                                        :disabled="!hasFidelidade"
                                        dense
                                        inset
                                        hide-details
                                    />
                                    <span class="caption">
                                        Com essa opção ativa seus clientes recebem 1 ponto a cada R$1,00 gasto
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-switch
                                        v-model="form.fidelidade.resgate_ativo"
                                        label="Permitir troca de pontos por prêmios"
                                        class="pb-2"
                                        :disabled="!hasFidelidade"
                                        dense
                                        inset
                                        hide-details
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-footer fixed class="pa-4">
                    <v-btn link large text color="warning" to="/">Cancelar</v-btn>
                    <v-spacer />
                    <v-btn color="success" large @click="salvar" :loading="loading">
                        <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                    </v-btn>
                </v-footer>
            </v-content>
            <Steps
                :visible="stepsVisible"
                @close="stepsVisible = false"
                @save="fillSteps"
            />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ImageInput from '@/components/ImageInput';
import MapViewModal from '@/components/MapViewModal';
import Steps from '@/pages/cadastro/Steps';
import { mapMutations, mapState } from 'vuex';
import { hasModule } from '@/utils/module';
import { getTimeErrors } from '@/utils/validate';
import timezones from '@/utils/timezones';

export default {
    name: 'Configurações',

    components: {
        BaseContainer,
        ImageInput,
        MapViewModal,
        Steps,
    },

    data: () => ({
        users: [{id: null, name: ''}],
        sino: null,
        loading: false,
        mapVisible: false,
        stepsVisible: false,
        formasEntrega: [],
        wppInstanceKey: '',
        wppQrBase64: '',
        wppConected: false,
        user: null,
        form: {
            uf: '',
            rua: '',
            slogan: '',
            numero: '',
            bairro: '',
            dominio: '',
            fantasia: '',
            whatsapp: '',
            facebook: '',
            municipio: '',
            chave_pix: '',
            instagram: '',
            razao_social: '',
            tempo_entrega: '',
            tempo_retirada: '',
            usuario_impressao: '',
            pedido_minimo_entrega: '',
            pedido_minimo_retirada: '',
            pedido_minimo_frete_gratis: '',
            valor_limite_taxa_gratis: '',
            geolocalizacao: {},
            logo: [],
            banner: [],
            formas_entrega: [],
            formas_pagamento: [],
            frete_tipo: 'Bairro',
            controle_estoque: false,
            exibir_estoque_cardapio: true,
            confirmar_pedidos_automaticamente: false,
            permitir_anuncios: true,
            horarios: [
                {isoWeekday: 1, diaSemana: 'SEGUNDA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                {isoWeekday: 2, diaSemana: 'TERCA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                {isoWeekday: 3, diaSemana: 'QUARTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                {isoWeekday: 4, diaSemana: 'QUINTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                {isoWeekday: 5, diaSemana: 'SEXTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                {isoWeekday: 6, diaSemana: 'SÁBADO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                {isoWeekday: 7, diaSemana: 'DOMINGO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
            ],
            gateways_pagamento: [],
            impressao: { vias: {} },
            agendamento: {},
            tema: {},
            mural: {},
            volumeSino: 100,
            whatsapp_order_status_messages: [],
            whatsapp_greeting_message: '',
            whatsapp_away_message: '',
            supervisor_password: '',
            facebook_pixel_id: null,
            google_tag_id: null,
            order_bump: null,
            fidelidade: {},
            whatsapp_bot_active: true,
        },
        initImpressao: {
            vias: {},
            tamanhoFonte: '14',
            mensagemFinal: '',
            opcoesTipoAtivo: false,
            servicoImpressao: 'USB',
            impressaoAutomatica: true,
            linkImpressaoWhatsapp: false,
            imprimirAlteracoes: false,
        },
        gateways: [
            {
                id: 'mercado_pago',
                descricao: 'Mercado Pago'
            },
        ],
        gateway: null,
        servicosImpressao: [
            'USB',
            'RawBT',
            'App ZaperMenu',
            // 'Chrome Bluetooth',
        ],
        passos: [],
        grupos: [],
        deviceTokens: [],
        freteTipos: [
            {
                value: 'Município',
                text: 'Município',
            },
            {
                value: 'Bairro',
                text: 'Bairro',
            },
            {
                value: 'Distância',
                text: 'Raio de Distância',
            },
            {
                value: 'Quilometragem',
                text: 'Quilometragem',
            },
            {
                value: 'A combinar',
                text: 'A combinar',
            },
        ],
    }),

    mounted() {
        this.sino = new Audio(require('@/assets/sounds/sino.mp3'));
        this.consultar();
        this.infoWppInstance();
        this.ws();
    },

    beforeDestroy() {
        document.removeEventListener('ws-qrcode', this.setWppQrCode);
        document.removeEventListener('ws-open', this.setWppConected);
    },

    watch: {
        'form.mural.cor'(v) {
            (typeof v !== 'string')
                && (this.form.mural.cor = v.hexa);
        },
    },

    computed: {
        ...mapState([
            'usuario',
            'produtos',
        ]),

        geolocalizacao: {
            get: function () {
                return `${this.form?.geolocalizacao?.latitude || ''}, ${ this.form?.geolocalizacao?.longitude || ''}`
            },

            set: function (v) {
                if (typeof v == 'string') {
                    const geo = v.split(', ');

                    this.form.geolocalizacao = {
                        latitude: geo[0],
                        longitude: geo[1]
                    };

                    return
                }

                this.form.geolocalizacao = v;
            }
        },

        tempoEntrega: {
            get() {
                return this.form.tempo_entrega?.split(' - ') || [20, 60];
            },

            set(v) {
                this.form.tempo_entrega = v.join(' - ');
            },
        },

        tempoRetirada: {
            get() {
                return this.form.tempo_retirada?.split(' - ') || [20, 60];
            },

            set(v) {
                this.form.tempo_retirada = v.join(' - ');
            },
        },

        orderBump: {
            get() {
                return this.form.order_bump?.produtos;
            },

            set(v) {
                if (!this.form.order_bump?.produtos?.length) {
                    this.form.order_bump = {};
                }
                this.form.order_bump.produtos = v;
            },
        },

        orderBumpMin: {
            get() {
                return this.form.order_bump?.min;
            },

            set(v) {
                if (!this.form.order_bump?.produtos?.length) {
                    this.form.order_bump = {};
                }
                this.form.order_bump.min = v;
            },
        },

        orderBumpMax: {
            get() {
                return this.form.order_bump?.max;
            },

            set(v) {
                if (!this.form.order_bump?.produtos?.length) {
                    this.form.order_bump = {};
                }
                this.form.order_bump.max = v;
            },
        },

        orderBumpTitulo: {
            get() {
                return this.form.order_bump?.titulo;
            },

            set(v) {
                if (!this.form.order_bump?.produtos?.length) {
                    this.form.order_bump = {};
                }
                this.form.order_bump.titulo = v;
            },
        },

        orderBumpGroupsInclude: {
            get() {
                return this.form.order_bump?.gruposInclusos;
            },

            set(v) {
                if (!this.form.order_bump?.gruposInclusos) {
                    this.form.order_bump.gruposInclusos = [];
                }
                this.form.order_bump.gruposInclusos = v;
            },
        },

        orderBumpGroupsNotInclude: {
            get() {
                return this.form.order_bump?.gruposNaoInclusos;
            },

            set(v) {
                if (!this.form.order_bump?.gruposNaoInclusos) {
                    this.form.order_bump.gruposNaoInclusos = [];
                }
                this.form.order_bump.gruposNaoInclusos = v;
            },
        },

        retirada: {
            get() {
                return this.form.formas_entrega?.includes(1);
            },

            set(v) {
                if (v) {
                    this.form.formas_entrega.push(1);
                    return;
                }

                const index = this.form.formas_entrega.findIndex(e => e === 1);
                this.form.formas_entrega.splice(index, 1);
            },
        },

        delivery: {
            get() {
                return this.form.formas_entrega?.includes(2);
            },

            set(v) {
                if (v) {
                    this.form.formas_entrega.push(2);
                    return;
                }

                const index = this.form.formas_entrega.findIndex(e => e === 2);
                this.form.formas_entrega.splice(index, 1);
            },
        },

        local: {
            get() {
                return this.form.formas_entrega?.includes(3);
            },

            set(v) {
                if (v) {
                    this.form.formas_entrega.push(3);
                    return;
                }

                const index = this.form.formas_entrega.findIndex(e => e === 3);
                this.form.formas_entrega.splice(index, 1);
            },
        },

        isPixEnabled() {
            return this.form.formas_pagamento?.includes(3);
        },

        largeScreen() {
            return this.$vuetify.breakpoint.width > 600;
        },

        showOnlinePayment() {
            return hasModule('PAGAMENTO_ONLINE');
        },

        hasFidelidade() {
            return hasModule('FIDELIDADE');
        },

        timezones() {
            return timezones;
        },

        userName() {
            return this.user?.name || '';
        },

        userWhatsapp() {
            return this.user?.id ? this.user?.id.split(':')[0] : '';
        },

        getProdutos() {
            return this.produtos.filter(e => !e.hasOwnProperty('produtos'));
        },
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        toogleSegundoHorario(diaSemana, option) {
            const horarios = (option == 'funcionamento')
                ? this.form.horarios.find(f => f.diaSemana == diaSemana)
                : this.form.agendamento.horarios.find(f => f.diaSemana == diaSemana);

            horarios.segundoHorarioAtivo = !horarios.segundoHorarioAtivo;

            this.$forceUpdate();
        },

        onChangeFrete() {
            (this.form.frete_tipo == 'Distância') && this.openGmaps();
        },

        openGmaps() {
            this.mapVisible = true;
            // navigator.geolocation.getCurrentPosition(pos => {
            //     this.form.coordenadas = {
            //         latitude: pos.coords.latitude,
            //         longitude: pos.coords.longitude
            //     };
            //     this.coordenadas = `${this.form?.coordenadas?.latitude || ''}, ${ this.form?.coordenadas?.longitude || ''}`
            // });
        },

        async consultar() {
            const users = this.$http.get('usuarios');
            const options = this.$http.get('configuracoes/get-options');
            const configs = this.$http.get('configuracoes');
            const passos = this.$http.get('passos');
            const grupos = this.$http.get('grupos');

            this.$root.$emit('loading', true);
            Promise.all([users, options, configs, passos, grupos]).then(resp => {
                this.users = resp[0].data.data;

                this.formasEntrega = resp[1].data.data.formasEntrega;
                this.passos = resp[3].data.data;
                this.grupos = resp[4].data.data;

                const data = resp[2].data;
                !data.horarios.length && (data.horarios = this.form.horarios);
                data.impressao = data.impressao || this.initImpressao;
                data.volumeSino = localStorage.getItem('volumeSino') || 100;

                this.form = data;

                if (this.form.impressao.servicoImpressao === 'App ZaperMenu') {
                    this.getDeviceTokens();
                }

                this.$root.$emit('loading', false);
            });
        },

        salvar() {
            const horarioError = getTimeErrors(this.form.horarios);

            if (horarioError) {
                this.notify(horarioError, 'warning');
                return;
            }

            if (this.form.agendamento.ativo && !this.form.agendamento.ocultarHorario) {
                const agendamentoError = getTimeErrors(this.form.agendamento.horarios);

                if (agendamentoError) {
                    this.notify(agendamentoError, 'warning');
                    return;
                }
            }

            const data = JSON.parse(JSON.stringify(this.form));

            !data.usuario_impressao && (data.usuario_impressao = null);
            localStorage.setItem('volumeSino', data.volumeSino);

            this.loading = true;
            this.$http.put('configuracoes/1', data).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                    return;
                }

                this.notify('Atualizado com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        onChangePosition(newPosition) {
            this.form.geolocalizacao = {
                latitude: newPosition.latitude,
                longitude: newPosition.longitude
            };
        },

        playTest() {
            this.sino.volume = this.form.volumeSino / 100;
            this.sino.play();
        },

        connectWpp() {
            this.wppInstanceKey
                ? this.qrbase64WppInstance()
                : this.initWppInstance();
        },

        async infoWppInstance() {
            const { data } = (await this.$http.get('whatsapp-instance/info')).data;

            this.wppInstanceKey = data?.instance_data?.instance_key || null;
            this.wppConected = data?.instance_data?.phone_connected;
            this.user = data?.instance_data?.user;
        },

        async initWppInstance() {
            const { data } = (await this.$http.get('whatsapp-instance/init')).data;

            if (!data.key) {
                return;
            }

            this.wppInstanceKey = data.key;
        },

        qrbase64WppInstance() {
            this.$http.get(`whatsapp-instance/qrbase64?key=${this.wppInstanceKey}`).then(async resp => {
                if (!resp.data.data.qrcode.trim()) {
                    await this.resetInstance();
                    return;
                }

                this.wppQrBase64 = resp.data.data.qrcode;
            });
        },

        async resetInstance() {
            await this.deleteWppInstance();
            await this.initWppInstance();
        },

        // logoutWppInstance() {
        //     this.$http.delete(`whatsapp-instance/logout?key=${this.wppInstanceKey}`).then(resp => {
        //         this.wppConected = false;

        //         if (resp.data.data.error) {
        //             this.notify(resp.data.data.message, 'warning');
        //             return;
        //         }
        //     });
        // },

        deleteWppInstance() {
            this.$http.delete(`whatsapp-instance/delete?key=${this.wppInstanceKey}`).then(resp => {
                this.wppInstanceKey = '';
                this.wppConected = false;

                if (resp.data.data.error) {
                    this.notify(resp.data.data.message, 'warning');
                    return;
                }
            });
        },

        ws() {
            document.addEventListener('ws-qrcode', this.setWppQrCode);
            document.addEventListener('ws-open', this.setWppConected);
        },

        setWppConected() {
            this.infoWppInstance();
        },

        setWppQrCode(event) {
            this.wppQrBase64 = event.detail;
        },

        addVariavel(status, variable) {
            const msg = this.form.whatsapp_order_status_messages.find(e => e.status === status);

            msg.message += '{{' + variable + '}}';
        },

        getLabel(status) {
            const labels = [
                { status: 'confirmado', label: 'Pedido Aceito' },
                { status: 'cancelado', label: 'Pedido Cancelado' },
                { status: 'em_preparacao', label: 'Pedido em Preparação' },
                { status: 'saiu_para_entrega', label: 'Pedido Saiu para Entrega' },
                { status: 'aguardando_retirada', label: 'Pedido Aguardando Retirada' },
                { status: 'concluido', label: 'Pedido Finalizado' },
                { status: 'pago', label: 'Pedido Pago (online)' },
            ];

            const result = labels.find(e => e.status === status);

            return result?.label || '';
        },

        onGatewaySelect() {
            const types = this.form.gateways_pagamento.map(e => e.type);
            if (types.includes(this.gateway)) {
                return;
            }

            if (this.gateway === 'mercado_pago') {
                this.form.gateways_pagamento.push({
                    ativo: true,
                    type: 'mercado_pago',
                    public_key: '',
                    access_token: '',
                });
            }
        },

        getGatewayName(type) {
            return this.gateways.find(g => g.id === type)?.descricao || '';
        },

        formatTimeString(min) {
            const hours = Math.floor(min / 60);
            const minutes = min % 60;

            if (hours > 0 && minutes > 0) {
                return `${hours}h${minutes}min`;
            } else if (hours > 0) {
                return `${hours}h`;
            } else {
                return `${minutes}min`;
            }
        },

        fillSteps() {
            this.$http.get('passos').then(resp => {
                this.passos = resp.data.data;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        downloadPrintApp() {
            this.salvar();
            window.open('https://play.google.com/store/apps/details?id=com.jonatan.zapermenu');
        },

        getDeviceTokens() {
            this.$http.get('device-tokens').then(resp => {
                this.deviceTokens = resp.data.data.map(e => ({
                    ...e,
                    token: this.abreviarToken(e.token),
                }));
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        abreviarToken(token) {
            if (token.length <= 16) {
                return token;
            }

            const primeiros = token.slice(0, 12);
            const ultimos = token.slice(-12);

            return `${primeiros}...${ultimos}`;
        },

        showCurrentTime() {
            if (!this.form.timezone) {
                return;
            }
            const currentTime = this.moment().tz(this.form.timezone).format('HH:mm');
            this.notify(`Horário atual: ${currentTime}`);
        }
    },
}
</script>

<style scoped>
input[type="file"] {
    display: none;
}

label {
    padding: 10px 10px;
    width: 130px;
    height: 44px;
    background-color: #333;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin-top: 25px;
    cursor: pointer;
}

.mdi-refresh {
    color: black !important;
}

.v-fieldset {
    border-color: #fff;
    border-radius: 5px;
    padding: 8px 8px 20px 8px;
}

.v-legend {
    color: #707070;
}

.v-expansion-panel-content {
    margin-top: 16px;
}

.color-gray {
    color: #808080;
}
</style>
